<template>
    <div>
        <v-app-bar
            id="home-app-bar"
            app
            color="white"
            elevation="1"
            height="100"
        >
            <base-img
                :src="require('@/assets/logo-white.jpeg')"
                contain
                max-width="178"
                width="100%"
            />

            <v-spacer />

            <div>
                <v-tabs class="hidden-sm-and-down" optional>
                    <v-tab
                        v-for="(name, i) in items"
                        :key="i"
                        :to="{ name }"
                        :exact="name === 'Home'"
                        :ripple="false"
                        active-class="text--primary"
                        class="font-weight-bold"
                        min-width="96"
                        text
                        >{{ name }}</v-tab
                    >
                </v-tabs>
            </div>

            <v-app-bar-nav-icon
                class="hidden-md-and-up"
                @click="drawer = !drawer"
            />
        </v-app-bar>

        <home-drawer v-model="drawer" :items="items" />
    </div>
</template>

<script>
export default {
    name: 'HomeAppBar',

    components: {
        HomeDrawer: () => import('./Drawer'),
    },

    data: () => ({
        drawer: null,
        items: ['Home', 'Services', 'Products', 'Contact'],
        users_items: [
            {
                menu_item: 'DashBoard',
                route_item: 'dashboard',
            },
            {
                menu_item: 'Invest',
                route_item: 'invest',
            },
            {
                menu_item: 'Withdrawal',
                route_item: 'withdrawal',
            },
            {
                menu_item: 'My Investments',
                route_item: 'my-investments',
            },
            {
                menu_item: 'My Withdrawals',
                route_item: 'my-withdrawals',
            },
            {
                menu_item: 'Affliates',
                route_item: 'affliates',
            },
            {
                menu_item: 'Logout',
                route_item: 'logout',
            },
        ],

        users_routes_items: [
            'dashboard',
            'invest',
            'withdraw',
            'my-investment',
            'my-withdrawals',
            'affliates',
            'logout',
        ],
    }),
};
</script>

<style lang="sass">
#home-app-bar
.v-tabs-slider
    max-width: 24px
    margin: 0 auto

.v-tab
    &::before
        display: none
</style>
